var App = {

    //Do Nothing

};







//Default functions
jQuery(document).ready(function ($) {



    /**
     *
     *	ON CLICK: [data-open-page]
     *		- Redirect to the Edit User Page
     *
     * 	Params:
     * 		n/a
     *
     **/
    $('body').on('click', '[data-open-page]', function (e) {

        e.preventDefault();

        if ($('[name="' + $(this).data('element') + '"]').val() !== '') {

            window.location.href = $(this).data('open-page') + $('[name="' + $(this).data('element') + '"]').val()

        }

    });





    /**
     *
     *	CLICK: #add-user
     *		- Add another User Select to the Form
     *
     * 	Params:
     * 		n/a
     *
     **/
    $('body').on('click', '[data-add-row]', function (e) {

        e.preventDefault();

        //Get the Field
        var $el     = $('[name="' + $(this).data('element') + '"]:first'),
            html    = $el.clone().get(0).outerHTML;

        //Add the Select
        if( $el.is('.select2-hidden-accessible') )
            html += $el.next().clone().get(0).outerHTML;

        $(this).parents('[data-row-wrapper]').append(
            '<div class="row clearfix">' +
            html +
            '<button class="button-small-red" data-remove-row type="button">X</button>' +
            '</div>'
        );

        $('[name="' + $(this).data('element') + '"]:last').val('');

    });





    /**
     *
     *	ON CLICK: [data-remove-row]
     *		- Removes the current HTML Row
     *
     * 	Params:
     * 		n/a
     *
     **/
    $('body').on('click', '[data-remove-row]', function (e) {

        e.preventDefault();

        $(this).parent().remove();

    });





    /**
     *
     *	ON CLICK: [submit-onchange]
     *		- Submit the form when an element changes
     *
     * 	Params:
     * 		n/a
     *
     **/
    $('body').on('change', '[submit-onchange]', function (e) {

        e.preventDefault();

        if ($(this).val() != '') {

            $(this).parents('form').submit();

        }

    });




    /**
     *
     *	LOAD:
     *		- Clear job input on load
     *
     * 	Params:
     * 		n/a
     *
     **/

    if ('#job') {
        $('#job').load(function (e) {
            $(this).val() = '';

        });
    }







    /**
     *
     *	$.ajaxSetup
     *		- Adds the CSRF Token to each Ajax Call
     *
     **/
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
        }
    });


    /**
    *
    *   selectize
    *       - add select2 to dropdowns
    *   
    *   Params:
    *       n/a
    *
    **/
    $('#company').select2();
    $('#company2').select2();
    //$('#job').select2();
    //$('#job2').select2();
    $('#user').select2();
    $('#user2').select2();
    $('select.third').select2();







});